import { useEffect, useState, useRef, ReactInstance, RefObject } from "react";
import { useReduxState, useActions } from "store";
import { AppBarWithMenuTemplate, SideMenu } from "@nait-aits/ui";
import styled from "styled-components";
import { Button } from "@mui/material";
import "./quizResultStyle.css";
import ErrorBox from "components/errorHandling/errorBox/ErrorBox";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  QuizOutlined,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import useConfirm from "components/hooks/useConfirm";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import ReactToPrint from "react-to-print";
import _orderBy from "lodash/orderBy";

export type QuizItem = {
  quizName: string;
  studentName: string;
  studentId: string;
  submissionDate: string;
  quizHtml: string;
};

const QuizManager = () => {
  const [selectedQuizGroup, setSelectedQuizGroup] = useState("");
  const [viewResultsQuiz, setViewResultsQuiz] = useState<number | undefined>();
  const [viewPendingQuiz, setViewPendingQuiz] = useState<string | undefined>();
  const [openConfirm, ConfirmBox] = useConfirm();

  const quizesState = useReduxState((e) => e.quizManager.loadQuizzes);
  const pendings = useReduxState((e) => e.quizManager.loadPendingQuizzes);
  const state = useReduxState((e) => e.quizManager.loadQuizResults);
  const deleteState = useReduxState((e) => e.quizManager.deleteQuizzes);
  const isLoading =
    quizesState.isLoading ||
    pendings.isLoading ||
    state.isLoading ||
    deleteState.isLoading;

  const actions = useActions().quizManager;

  useEffect(() => {
    document.title = "DI Viewer - Quiz Manager";
    actions.loadQuizzes();
    return () => {
      actions.reset();
    };
  }, []);
  useEffect(() => {
    const first = quizesState.data?.[0];
    if (first) setSelectedQuizGroup(first);
  }, [quizesState.data]);
  useEffect(() => {
    if (selectedQuizGroup) {
      actions.loadQuizResults({ quizName: selectedQuizGroup });
      actions.loadPendingQuizzes({ quizName: selectedQuizGroup });
    }
  }, [selectedQuizGroup]);
  useEffect(() => {
    if (
      viewResultsQuiz &&
      !state.data?.find((e) => e.quizId === viewResultsQuiz)?.quizResultsData
    )
      actions.loadQuizResult({ id: viewResultsQuiz });
  }, [viewResultsQuiz]);
  useEffect(() => {
    if (
      viewPendingQuiz &&
      !pendings.data?.find((e) => e.id === viewPendingQuiz)?.quizResultsData
    )
      actions.loadPendingQuiz({ quizGuid: viewPendingQuiz });
  }, [viewPendingQuiz]);

  return (
    <AppBarWithMenuTemplate
      isLoading={isLoading}
      appBarRightContent={<HeaderButtons />}
      appBarLeftContent={() => <Title>{selectedQuizGroup}</Title>}
      appBarTitle={selectedQuizGroup}
      menu={
        <SideMenu
          label="Quiz Manager"
          menuGroups={[
            {
              label: "",
              items:
                quizesState.data?.map((e) => {
                  return {
                    label: e,
                    icon: <QuizOutlined />,
                    selected: e === selectedQuizGroup,
                    onClick: () => {
                      setSelectedQuizGroup(e);
                      setViewResultsQuiz(undefined);
                      setViewPendingQuiz(undefined);
                    },
                  };
                }) ?? [],
            },
          ]}
        />
      }
    >
      <div style={{ margin: 20 }}>
        {state.error && <ErrorBox error={state.error} />}
        {deleteState.error && <ErrorBox error={deleteState.error} />}
        {ConfirmBox}
        {/* <ConfirmBox
          data={confirm}
          onCancel={() => {
            setConfirm(undefined);
          }}
        /> */}
        {(viewResultsQuiz || viewPendingQuiz) && (
          <QuizResults
            content={
              (viewResultsQuiz
                ? state.data?.find((e) => e.quizId === viewResultsQuiz)
                    ?.quizResultsData
                : viewPendingQuiz
                ? pendings.data?.find((e) => e.id === viewPendingQuiz)
                    ?.quizResultsData
                : "<div />") ?? "<div />"
            }
            submittedDate={
              state.data?.find((e) => e.quizId === viewResultsQuiz)
                ?.dateSubmitted ?? ""
            }
            onClose={() => {
              setViewResultsQuiz(undefined);
              setViewPendingQuiz(undefined);
            }}
            onDelete={
              viewResultsQuiz
                ? () => {
                    openConfirm({
                      message: "Are you sure you want to delete this quiz?",
                      onOk: () => {
                        const found = state.data?.find(
                          (e) => e.quizId === viewResultsQuiz
                        );

                        if (found) {
                          actions.deleteQuizzes({ quizIds: [found.quizId] });
                        }
                        setViewResultsQuiz(undefined);
                      },
                    });
                  }
                : viewPendingQuiz &&
                  pendings.data?.find((e) => e.id === viewPendingQuiz)
                    ?.unsubmitted
                ? () => {
                    openConfirm({
                      message:
                        "This is a unsubmitted quiz, are you sure you want to archive this quiz?",
                      onOk: () => {
                        actions.archiveQuiz({ quizGuid: viewPendingQuiz });
                        setViewPendingQuiz(undefined);
                      },
                    });
                  }
                : undefined
            }
          />
        )}
        {state.data && selectedQuizGroup && (
          <div
            style={{
              display: !viewResultsQuiz && !viewPendingQuiz ? "block" : "none",
            }}
          >
            <QuizResultsTable
              quizName={selectedQuizGroup}
              disabled={isLoading}
              onDelete={(quizIds, callback) => {
                openConfirm({
                  message: "Are you sure you want to delete these quizzes?",
                  onOk: () => {
                    actions.deleteQuizzes({ quizIds });
                    callback();
                  },
                });
              }}
              onViewResults={(id) => {
                setViewResultsQuiz(id);
              }}
              onViewPending={(guid) => setViewPendingQuiz(guid)}
              items={
                state.data?.filter((e) => e.quizName === selectedQuizGroup) ||
                []
              }
            />
          </div>
        )}
      </div>
    </AppBarWithMenuTemplate>
  );
};

type QuizResultsProps = {
  onClose: () => void;
  onDelete?: () => void;
  content: string;
  submittedDate: string;
};

const QuizResults = (props: QuizResultsProps) => {
  const { onClose, onDelete, content, submittedDate } = props;

  const temp = document.createElement("div");
  temp.innerHTML = content;
  const contentDiv = temp.getElementsByTagName("div")[0];
  const total = contentDiv.getElementsByClassName("quiz-result").length;

  const printRef = useRef<ReactInstance>(null);
  const [wrong, setWrong] = useState(0);
  const [half, setHalf] = useState(0);
  const score = total - wrong - half * 0.5;

  const Score = () => (
    <div className="row mark-result">
      <div className="col-sm-6">Date Submitted: {submittedDate}</div>
      <div className="col mark-result__title">Student Score:</div>
      <div className="col mark-result__score">
        {score}/{total}
      </div>
      <div className="col mark-result__percent">
        {((score / total) * 100).toFixed(0)}%
      </div>
    </div>
  );

  return (
    <div>
      <BtnDiv>
        <Button variant="contained" onClick={onClose}>
          Return
        </Button>
        <ReactToPrint
          trigger={() => <Button variant="contained">Print</Button>}
          content={() => printRef.current}
        />
        {onDelete && (
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete Quiz
          </Button>
        )}
      </BtnDiv>
      <div
        onClick={(e) => {
          const element = e.target as HTMLElement;
          const row = element.classList.contains("quiz-result")
            ? element
            : element.parentElement?.classList.contains("quiz-result")
            ? element.parentElement
            : undefined;
          if (row) {
            if (row.classList.contains("incorrect")) {
              row.classList.remove("incorrect");
              row.classList.add("half-mark");
              setWrong(wrong - 1);
              setHalf(half + 1);
            } else if (row.classList.contains("half-mark")) {
              row.classList.remove("half-mark");
              setHalf(half - 1);
            } else {
              row.classList.add("incorrect");
              setWrong(wrong + 1);
            }
          }
        }}
      >
        <div ref={printRef as RefObject<HTMLDivElement>}>
          <Score />
          <div
            style={{ border: "2px solid #70abde" }}
            dangerouslySetInnerHTML={{ __html: contentDiv.outerHTML }}
          />
          <Score />
        </div>
      </div>
    </div>
  );
};

type QuizResultsTableProps = {
  disabled: boolean;
  onDelete: (quizIds: number[], callback: Function) => void;
  onViewResults: (quizId: number) => void;
  onViewPending: (quizGuid: string) => void;
  quizName: string;
  items: {
    quizId: number;
    logid: string;
    studentId: string;
    studentName: string;
    dateSubmitted: string;
    quizResultsData?: string;
  }[];
};

const QuizResultsTable = (props: QuizResultsTableProps) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [orderColumn, setOrderColumn] = useState({
    name: undefined as string | undefined,
    byDesc: false,
  });
  const actions = useActions().quizManager;

  const {
    disabled,
    onDelete,
    items,
    onViewResults,
    onViewPending,
    quizName,
  } = props;
  const quizItems = _orderBy(
    items,
    orderColumn.name === "dateSubmitted"
      ? (e) => new Date(e.dateSubmitted)
      : orderColumn.name,
    orderColumn.byDesc ? "desc" : "asc"
  ) as typeof items;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        <TopBtnDiv>
          <Button
            id="btnRefresh"
            variant="contained"
            onClick={() => {
              actions.loadQuizResults({ quizName });
              actions.loadPendingQuizzes({ quizName });
            }}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={selectedItems.length === 0}
            onClick={() => {
              onDelete(selectedItems, () => setSelectedItems([]));
            }}
          >
            Delete Selected Quizzes
          </Button>
        </TopBtnDiv>
      </div>
      {items.length > 0 && (
        <>
          <h3>Submitted Quizzes:</h3>
          <Table>
            <thead>
              <tr>
                <HeadingCell width={50}>
                  <Checkbox
                    disabled={disabled}
                    onToggle={() => {
                      const isSelected =
                        selectedItems.length === quizItems.length;

                      if (isSelected) {
                        setSelectedItems([]);
                      } else {
                        setSelectedItems(quizItems.map((e) => e.quizId));
                      }
                    }}
                    isChecked={
                      selectedItems.length === quizItems.length &&
                      selectedItems.length > 0
                    }
                  />
                </HeadingCell>

                <HeadingCell
                  width={300}
                  onClick={() =>
                    setOrderColumn({
                      name: "studentName",
                      byDesc:
                        orderColumn.name === "studentName"
                          ? !orderColumn.byDesc
                          : false,
                    })
                  }
                >
                  Student Name
                  {orderColumn.name === "studentName" &&
                    (orderColumn.byDesc ? <Down /> : <Up />)}
                </HeadingCell>
                <HeadingCell
                  width={150}
                  onClick={() =>
                    setOrderColumn({
                      name: "studentId",
                      byDesc:
                        orderColumn.name === "studentId"
                          ? !orderColumn.byDesc
                          : false,
                    })
                  }
                >
                  Student Id
                  {orderColumn.name === "studentId" &&
                    (orderColumn.byDesc ? <Down /> : <Up />)}
                </HeadingCell>
                <HeadingCell
                  width={200}
                  onClick={() =>
                    setOrderColumn({
                      name: "dateSubmitted",
                      byDesc:
                        orderColumn.name === "dateSubmitted"
                          ? !orderColumn.byDesc
                          : false,
                    })
                  }
                >
                  Submission Date
                  {orderColumn.name === "dateSubmitted" &&
                    (orderColumn.byDesc ? <Down /> : <Up />)}
                </HeadingCell>
                <HeadingCell width={100}></HeadingCell>
              </tr>
            </thead>
            <tbody>
              {quizItems.map((e) => (
                <QuizRow
                  key={e.quizId}
                  {...e}
                  disabled={disabled}
                  isSelected={selectedItems.indexOf(e.quizId) >= 0}
                  onViewResults={() => {
                    onViewResults(e.quizId);
                  }}
                  onToggleSelected={() => {
                    const found = selectedItems.indexOf(e.quizId);

                    if (found >= 0) {
                      selectedItems.splice(found, 1);
                    } else {
                      selectedItems.push(e.quizId);
                    }

                    setSelectedItems([...selectedItems]);
                  }}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
      <PendingTable unsubmitted={false} onViewPending={onViewPending} />
      <PendingTable unsubmitted onViewPending={onViewPending} />
    </>
  );
};

const PendingTable = ({
  unsubmitted,
  onViewPending,
}: {
  unsubmitted: boolean;
  onViewPending: (guid: string) => void;
}) => {
  const { data } = useReduxState((e) => e.quizManager.loadPendingQuizzes);
  const pendings = data?.filter((e) => e.unsubmitted === unsubmitted) ?? [];

  return pendings.length > 0 ? (
    <>
      <h3>{unsubmitted ? "Unsubmitted" : "Pending"} Quizzes:</h3>
      <Table>
        <thead>
          <tr>
            <HeadingCell width={300}>Student Name</HeadingCell>
            <HeadingCell width={150}>Student Id</HeadingCell>
            <HeadingCell width={200}>Started Time</HeadingCell>
            <HeadingCell width={100}></HeadingCell>
          </tr>
        </thead>
        <tbody>
          {pendings.map((e) => (
            <tr key={e.id}>
              <Cell>{e.studentName}</Cell>
              <Cell>{e.studentId}</Cell>
              <Cell>
                {new Date(e.dateStarted).toLocaleDateString("zh-Hans-CN", {
                  month: "2-digit",
                  year: "numeric",
                  day: "2-digit",
                })}{" "}
                {new Date(e.dateStarted).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                })}
              </Cell>
              <Cell>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => onViewPending(e.id)}
                >
                  View
                </Button>
              </Cell>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  ) : (
    <></>
  );
};

type QuizRowProps = {
  quizId: number;
  logid: string;
  studentId: string;
  studentName: string;
  dateSubmitted: string;
  quizResultsData?: string;
  isSelected: boolean;
  disabled: boolean;
  onViewResults: () => void;
  onToggleSelected: () => void;
};

const QuizRow = (props: QuizRowProps) => {
  const {
    disabled,
    dateSubmitted,
    isSelected,
    studentId,
    studentName,
    onViewResults,
    onToggleSelected,
  } = props;

  return (
    <tr className={isSelected ? "selected" : undefined}>
      <Cell>
        <Checkbox
          disabled={disabled}
          onToggle={onToggleSelected}
          isChecked={isSelected}
        />
      </Cell>
      <Cell>{studentName}</Cell>
      <Cell>{studentId}</Cell>
      <Cell>{dateSubmitted}</Cell>
      <Cell>
        <Button
          size="small"
          disabled={disabled}
          variant={"contained"}
          onClick={() => {
            onViewResults();
          }}
        >
          View
        </Button>
      </Cell>
    </tr>
  );
};

type CheckboxProps = {
  isChecked: boolean;
  disabled: boolean;
  onToggle: () => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { isChecked, onToggle, disabled } = props;

  return (
    <div
      style={{
        color: disabled ? "grey" : "#1976d2",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        onToggle();
      }}
    >
      {isChecked && <CheckBox />}
      {!isChecked && <CheckBoxOutlineBlank />}
    </div>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  cursor: default;
  & tbody tr:not(.selected):hover {
    background-color: #efefef;
  }
  & tr.selected {
    background-color: #b3d9ff;
  }
`;

const HeadingCell = styled.td<{ width?: number }>`
  ${(props) => props.width && `width: ${props.width}px;`}
  border-bottom: 1px solid black;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
`;

const Cell = styled.td`
  border-bottom: 1px solid black;
  padding: 10px;
`;

const Title = styled.span`
  display: inline-block;
  max-width: calc(100% - 50px);
  vertical-align: middle;
`;

const BtnDiv = styled.div`
  margin-bottom: 20px;
  & button {
    margin-left: 10px;
  }
`;

const Up = styled(ArrowUpward)`
  font-size: 16px !important;
`;
const Down = styled(ArrowDownward)`
  font-size: 16px !important;
`;

const TopBtnDiv = styled.div`
  position: fixed;
  top: 85px;
  right: 30px;
  & button {
    margin-left: 10px;
  }
`;

export default QuizManager;
