import { useState } from "react";
// import Button from "components/Button/Button";
// import IconButton from "components/IconButton/IconButton";
// import { accountCircleIcon } from "common/icons";
// import HoverMenu from "./HoverMenu";
import { Button, IconButton } from "@mui/material";
import { accountCircleIcon } from "components/Icons/Icons";
import HoverMenu from "./HoverMenu";

export type Props = {
  isAuthenticated: boolean;
  onLogInClick(): void;
  onLogOffClick(): void;
};

const AccountButton = ({
  onLogInClick,
  onLogOffClick,
  isAuthenticated,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  if (isAuthenticated) {
    return (
      <div style={{ position: "relative" }}>
        <IconButton
          onClick={() => setMenuOpen(true)}
          style={{ color: "white" }}
        >
          {accountCircleIcon}
        </IconButton>
        <HoverMenu
          isOpen={isMenuOpen}
          setMenuClosed={() => setMenuOpen(false)}
          handleLogOff={onLogOffClick}
        />
      </div>
    );
  }

  return (
    <Button onClick={onLogInClick} variant="contained">
      <span className="i-lock"></span>&nbsp;&nbsp;Login
    </Button>
  );
};

export default AccountButton;
