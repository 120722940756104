import { Button } from "@mui/material";
import { SimpleDialog } from "@nait-aits/ui";
import React, { ReactNode, useState } from "react";

export type Props = {};

type ItemProps = {
  message: string;
  onOk: () => void;
  onCancel?: () => void;
};

const Renderer = (props: {
  message?: string;
  onCancel?: () => void;
  onOk: () => void;
}) => {
  const { message, onCancel = () => {}, onOk } = props;

  return (
    <SimpleDialog>
      <div style={{ margin: 20, zIndex: 1 }}>
        <div>{message}</div>
        <div style={{ textAlign: "right", marginTop: 20 }}>
          <Button
            onClick={() => {
              onOk();
            }}
          >
            OK
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            color={"secondary"}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </SimpleDialog>
  );
};

const useConfirm = () => {
  const [data, setData] = useState<ItemProps | undefined>();

  const dataSetter = (props: {
    message: string;
    onOk: () => void;
    onCancel: () => void;
  }) => {
    setData({ ...props });
  };

  return [
    dataSetter,
    data ? (
      <Renderer
        message={data?.message}
        onCancel={() => {
          setData(undefined);
          data?.onCancel?.();
        }}
        onOk={() => {
          setData(undefined);
          data?.onOk();
        }}
      />
    ) : (
      <></>
    ),
  ] as [
    (props: {
      message: string;
      onOk: () => void;
      onCancel?: () => void;
    }) => void,
    ReactNode
  ];
};

export default useConfirm;
