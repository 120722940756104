import { IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { SimpleDialog } from "@nait-aits/ui";

export type Props = {
  name: string;
  subTitle?: string;
  createdDate?: string;
  fullPath: string;
  imageUrl?: string;
  metaData?: {
    key: string;
    value: string;
  }[];
  onDelete?: () => void;
};

type Dimensions = {
  height: number;
  width: number;
  naturalWidth: number;
  naturalHeight: number;
};

const FileInformation = (props: Props) => {
  const {
    name,
    imageUrl,
    createdDate,
    fullPath,
    metaData = [],
    onDelete,
  } = props;

  const [showFullImage, setShowFullImage] = useState(false);
  const [dimensions, setDimensions] = useState<Dimensions | undefined>();

  return (
    <div>
      {showFullImage && (
        <SimpleDialog handleBackgroundClick={() => setShowFullImage(false)}>
          <div onClick={() => setShowFullImage(false)}>
            <img src={imageUrl} alt="Full Preview" width={"100%"} />
          </div>
        </SimpleDialog>
      )}
      <ImageWrapper>
        <div>
          <img
            style={{ maxHeight: 600, maxWidth: 900 }}
            src={imageUrl}
            alt={name}
            onLoad={({ target }: { target: any }) => {

              setDimensions({
                height: target.height,
                width: target.width,
                naturalWidth: target.naturalWidth,
                naturalHeight: target.naturalHeight,
              });
            }}
          />
        </div>
      </ImageWrapper>
      <ImageBar>
        <div></div>
        <ButtonDiv>
          {onDelete && (
            <IconButton
              aria-label={`info about ${name}`}
              // style={{ color: "white" }}
              onClick={(event) => {
                onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            aria-label={`info about ${name}`}
            // style={{ color: "red" }}
            onClick={(event) => {
              setShowFullImage(true);
            }}
          >
            <FullscreenIcon />
          </IconButton>
        </ButtonDiv>
      </ImageBar>
      <FilePropsSection>
        <FilePropsRow>
          <FilePropsTitle>File Name: </FilePropsTitle>
          <FilePropsValue>{name}</FilePropsValue>
        </FilePropsRow>
        <FilePropsRow>
          <FilePropsTitle>Date Created: </FilePropsTitle>
          <FilePropsValue>
            {moment.utc(createdDate).local().format("YYYY-MM-DD hh:mm A")}
          </FilePropsValue>
        </FilePropsRow>
        {dimensions && (
          <FilePropsRow>
            <FilePropsTitle>Image Size: </FilePropsTitle>
            <FilePropsValue>
              {dimensions.naturalWidth} x {dimensions.naturalHeight}
            </FilePropsValue>
          </FilePropsRow>
        )}
        <FilePropsRow>
          <FilePropsTitle>File Path: </FilePropsTitle>
          <FilePropsValue>{fullPath}</FilePropsValue>
        </FilePropsRow>

        {metaData && (
          <FilePropsRow>
            <FilePropsTitle>Meta Data: </FilePropsTitle>
            <FilePropsMetaDataList>
              {metaData.map((md) => (
                <FilePropsMetaDataListitem key={md.key}>
                  {/* <FilePropsMetaDataTitle>{md.key}: </FilePropsMetaDataTitle> */}
                  <FilePropsMetaDataValue>
                    {md.key} : {md.value}
                  </FilePropsMetaDataValue>
                </FilePropsMetaDataListitem>
              ))}
            </FilePropsMetaDataList>
          </FilePropsRow>
        )}
      </FilePropsSection>
    </div>
  );
};

const ImageWrapper = styled.div`
  background-color: black;
  text-align: center;
  position: relative;
`;

const ImageBar = styled.div``;

const ButtonDiv = styled.div`
  text-align: right;
  width: 100%;
`;

const FilePropsSection = styled.div`
  padding: 0 20px;
  margin-top: -30px;
`;

const FilePropsRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const FilePropsTitle = styled.div`
  font-weight: bold;
  margin-right: 20px;
  width: 130px;
`;

const FilePropsValue = styled.div``;
const FilePropsMetaDataList = styled.div``;
const FilePropsMetaDataListitem = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const FilePropsMetaDataValue = styled.div``;

export default FileInformation;
