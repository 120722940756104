import { AppBarWithMenuTemplate, SimpleDialog } from "@nait-aits/ui";
import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";

import FileList from "components/FileList/FileList";
import FolderListSideMenu from "components/FolderListSideMenu/FolderListSideMenu";
import ErrorBox from "components/errorHandling/errorBox/ErrorBox";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";
import { msalInstance } from "index";
import { loginRequest } from "authConfig";
import { PathAndUpload } from "components/FileList/FileList";

export type Props = {
  selectedPath?: string;
};

const SampleBrowser = (props: Props) => {
  // const { selectedPath } = props;

  const state = useReduxState((e) => e.blobBrowser.browserItems);
  const deleteState = useReduxState((e) => e.blobBrowser.deleteFile);

  const actions = useActions().blobBrowser;

  const [selectedItem, setSelectedItem] = useState("");

  console.log(selectedItem);

  useEffect(() => {
    document.title = "DI Viewer - Image Browser";
    actions.loadDirectory({ fullPath: "" });
  }, []);

  var selectedData = selectedItem
    ? state.data?.find((e) => e.fullPath === selectedItem)
    : undefined;

  return (
    <div>
      {deleteState.isLoading && (
        <div>
          <SimpleDialog>Deleting File...</SimpleDialog>
        </div>
      )}
      <AppBarWithMenuTemplate
        isLoading={state.isLoading || deleteState.isLoading}
        appBarRightContent={<HeaderButtons />}
        appBarLeftContent={() => (
          <PathAndUpload
            currentPath={selectedData?.fullPath}
            onFolderClick={(fullPath) => {
              setSelectedItem(fullPath);
              actions.loadDirectory({ fullPath });
              console.log(fullPath);
            }}
            onFilesUploaded={(files, subfolderName) => {
              if (files) {
                getAuthToken().then((token) => {
                  let formData = new FormData();

                  var pathHelper = "";

                  if (subfolderName) {
                    pathHelper = `${subfolderName}/`;
                  }

                  files.forEach((file) => {
                    var filePath = `${selectedData?.fullPath}${pathHelper}${file.name}`;
                    formData.append(filePath, file);
                  });
                  fetch(`${process.env.REACT_APP_API_BASE}/Blob/Upload`, {
                    method: "POST",
                    body: formData,
                    headers: {
                      Authorization: token.Authorization,
                    },
                  }).then((e) => {
                    selectedData &&
                      actions.loadDirectory({
                        fullPath: selectedData.fullPath,
                      });
                  });
                });
              }
            }}
          />
        )}
        appBarTitle={selectedData ? selectedData.fullPath : ""}
        menu={
          <div>
            <div>
              {state.data && (
                <FolderListSideMenu
                  title={"Directory Listing"}
                  onPathExpanded={(fullPath) => {
                    actions.loadDirectory({ fullPath });
                  }}
                  selectedPath={selectedItem}
                  onPathSelected={(fullPath) => {
                    actions.loadDirectory({ fullPath });
                    setSelectedItem(fullPath);
                  }}
                  items={state.data
                    .filter((e) => !e.isFile)
                    .map((e) => {
                      return {
                        name: e.name,
                        fullPath: e.fullPath,
                        isLoading: e.isLoading,
                      };
                    })}
                />
              )}
            </div>
          </div>
        }
      >
        <div style={{ padding: 10 }}>
          {state.error && <ErrorBox error={state.error} />}
          {state.data && !state.error && (
            <div style={{ marginLeft: 20 }}>
              <FileList
                title={selectedData ? selectedData.fullPath : "root"}
                currentPath={selectedData?.fullPath}
                onFolderClick={(fullPath) => {
                  setSelectedItem(fullPath);
                  actions.loadDirectory({ fullPath });
                  console.log(fullPath);
                }}
                items={state.data
                  .filter(
                    (e) =>
                      // e.isFile &&
                      // e.isImage &&
                      (selectedData || !e.isFile) &&
                      e.parent === (selectedData ? selectedData?.fullPath : "")
                  )
                  .map((e) => {
                    return {
                      fullPath: e.fullPath,
                      name: e.name,
                      imageUrl: e.url,
                      createdDate: e.createdDate,
                      isFolder: !e.isFile,
                      isImage: e.isImage,
                    };
                  })}
                onFileDeleted={(fullPath) => {
                  actions.deleteFile({
                    fullPath: fullPath,
                  });
                }}
              />
            </div>
          )}
        </div>
      </AppBarWithMenuTemplate>
    </div>
  );
};

const getAuthToken = () => {
  var userAccount = msalInstance.getAllAccounts()[0];

  var authValue = msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: userAccount,
    })
    .then((msalResopnse) => {
      return { Authorization: "Bearer " + msalResopnse.accessToken };
    });

  return authValue;
};

export default SampleBrowser;
