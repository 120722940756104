import React from "react";
import { FetchError } from "@nait-aits/redux";

import styled from "styled-components";

export type Props = {
  error: FetchError | string;
};

const ErrorBox = (props: Props) => {
  const { error } = props;

  if (error instanceof FetchError) {
    if (error.errorCode === 401) {
      return (
        <ErrorWrapper>
          <h3>Unauthorized</h3>
          <div>You do not have access to view this page.</div>
        </ErrorWrapper>
      );
    }

    return (
      <ErrorWrapper>
        <div>errorCode: {error.errorCode}</div>
        <div>message: {error.message}</div>
        <div>status: {error.status}</div>
      </ErrorWrapper>
    );
  }

  return <ErrorWrapper>{error}</ErrorWrapper>;
};

const ErrorWrapper = styled.div`
  padding: 10px;
  border: 1px solid red;
  background-color: pink;
  border-radius: 0.25em;
`;

export default ErrorBox;
