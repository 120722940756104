import { MainPageTemplate } from "@nait-aits/ui";
import { Button } from "@mui/material";
import { performLogin } from "store/getAuthBearerToken";
import { useMsal } from "@azure/msal-react";

export type Props = {};

const NotLoggedIn = (props: Props) => {
  const { instance } = useMsal();
  return (
    <div>
      <MainPageTemplate
        appBarHeadingText={"Not Logged In"}
        body={
          <div>
            <h2>You need to be logged in to access this site.</h2>
            <div>
              <Button
                onClick={() => {
                  performLogin(instance);
                }}
                variant={"contained"}
              >
                Log In
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default NotLoggedIn;
