import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AccountButton from "./AccountButton";
import { performLogin } from "store/getAuthBearerToken";

export type Props = {};

const MSALAccountButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  return (
    <AccountButton
      isAuthenticated={isAuthenticated}
      onLogInClick={() => {
        performLogin(instance);
      }}
      onLogOffClick={() => {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }}
    />
  );
};

export default MSALAccountButton;
