import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileItemType } from "../FileList";
import FileItem from "./FileItem";

export type Props = {
  items: FileItemType[];
  imageWidth?: number;
  imagesPerPage?: number;
  onFolderClick?: (fullPath: string) => void;
  onImageSelected?: (fullPath: string) => void;
  onDelete?: (fullPath: string) => void;
};

const FileGrid = (props: Props) => {
  const {
    items,
    imageWidth,
    onImageSelected,
    onFolderClick,
    imagesPerPage = 20,
    onDelete,
  } = props;

  const [numberToTake, setNumberToTake] = useState(imagesPerPage);

  useEffect(() => {
    setNumberToTake(imagesPerPage);
    //eslint-disable-next-line
  }, [items]);

  var allImages = items.filter((e) => !e.isFolder && e.isImage);

  var imagesToShow = allImages.slice(0, numberToTake);

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {items
          .filter((e) => e.isFolder)
          .map((item, index) => (
            <FileItem
              key={`${item.fullPath}_${index}`}
              fullPath={item.fullPath}
              name={item.name}
              createdDate={item.createdDate}
              imageUrl={item.imageUrl}
              imageWidth={imageWidth}
              isFolder={item.isFolder}
              onImageSelected={() => {
                onFolderClick?.(item.fullPath);
              }}
            />
          ))}
        {imagesToShow.map((item, index) => (
          <FileItem
            key={`${item.fullPath}_${index}`}
            fullPath={item.fullPath}
            name={item.name}
            createdDate={item.createdDate}
            imageUrl={item.imageUrl}
            imageWidth={imageWidth}
            onDelete={
              onDelete
                ? () => {
                    onDelete?.(item.fullPath);
                  }
                : undefined
            }
            onImageSelected={() => {
              onImageSelected?.(item.fullPath);
            }}
          />
        ))}
      </div>
      {numberToTake < allImages.length && (
        <div style={{ textAlign: "center" }}>
          <div>
            {imagesToShow.length} of {allImages.length} images
          </div>
          <Button
            onClick={() => {
              setNumberToTake(numberToTake + imagesPerPage);
            }}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default FileGrid;
