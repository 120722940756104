import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import { PayloadAction } from "@reduxjs/toolkit";
import getAuthBearerToken from "store/getAuthBearerToken";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "blobBrowser";

export type DirectoryItem = {
  name: string;
  path: string;
  createdDate?: string;
  fullPath: string;
  isLoading: boolean;
  isExpanded?: boolean;
  isLoaded?: boolean;
  isFile: boolean;
  isImage?: boolean;
  parent?: string;
  url?: string;
};

type State = {
  browserItems: ReducerState<DirectoryItem[]>;
  deleteFile: ReducerState<DeleteFileReturn>;
};

var loadDirectory = createAsyncAction<
  DirectoryItem[], //return type
  { fullPath: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "LoadDirectory",
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Blob/GetDirectoryContents`,
  transformQueryParams: (p) => {
    return {
      path: p.fullPath,
    };
  },
  pending: (state, action) => {
    var found = state.browserItems.data?.find(
      (e) => e.fullPath === action.params.fullPath
    );
    state.browserItems.isLoading = true;
    state.browserItems.error = undefined;

    if (!state.browserItems.data) {
      state.browserItems.data = [];
    }

    //remove any with this path
    var items = state.browserItems.data.filter(
      (e) =>
        e.fullPath === action.params.fullPath ||
        !e.fullPath.startsWith(action.params.fullPath)
    );

    state.browserItems.data = [...items];

    if (found) {
      found.isLoading = true;
      found.isExpanded = true;
      found.isLoaded = false;
    }
  },
  fulfilled: (state, action) => {
    state.browserItems.isLoading = false;

    action.payload.forEach((element) => {
      state.browserItems.data?.push({
        ...element,
        parent: action.params.fullPath,
      });
    });

    var found = state.browserItems.data?.find(
      (e) => e.fullPath === action.params.fullPath
    );

    if (found) {
      found.isLoading = false;
      found.isLoaded = true;
    }
  },
  rejected: (state, action) => {
    state.browserItems.isLoading = false;
    state.browserItems.error = action.payload;

    var found = state.browserItems.data?.find(
      (e) => e.fullPath === action.params.fullPath
    );

    if (found) {
      found.isLoading = false;
      found.isLoaded = true;
    }
  },
});

type DeleteFileReturn = {};

var deleteFile = createAsyncAction<
  DeleteFileReturn, //return type
  { fullPath: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deleteFile",
  postAsJson: true,
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/Blob/DeleteFile`,
  pending: (state, action) => {
    state.deleteFile = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deleteFile.isLoading = false;

    var found =
      state.browserItems.data &&
      state.browserItems.data.find(
        (e) => e.fullPath === action.params.fullPath
      );

    if (found && state.browserItems.data) {
      var index = state.browserItems.data.indexOf(found);
      state.browserItems.data.splice(index, 1);
    }
  },
  rejected: (state, action) => {
    state.deleteFile.isLoading = false;
    state.deleteFile.error = action.payload;
  },
});

var slice = createSlice({
  name: controlName,
  initialState: {
    browserItems: {
      isLoading: false,
    },
    deleteFile: {
      isLoading: false,
    },
  } as State,
  reducers: {
    toggleExpand: (state, action: PayloadAction<{ fullPath: string }>) => {
      var found = state.browserItems.data?.find(
        (e) => e.fullPath === action.payload.fullPath
      );

      if (found) {
        found.isExpanded = !!!found.isExpanded;
      }
    },
  },
  extraReducers: {
    ...loadDirectory.reducer,
    ...deleteFile.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadDirectory: loadDirectory.action,
      deleteFile: deleteFile.action,
    },
  },
};

export default duck;
