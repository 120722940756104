import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";
import getAuthBearerToken from "store/getAuthBearerToken";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "quizManager";

export type LoadQuizResultsReturn = {
  quizId: number;
  logid: string;
  studentId: string;
  studentName: string;
  dateSubmitted: string;
  quizResultsData?: string;
  quizName: string;
};

export type PendingQuizReturn = {
  id: string;
  unsubmitted: boolean;
  studentId: string;
  studentName: string;
  dateStarted: string;
  quizResultsData?: string;
};

const loadQuizzes = createAsyncAction<string[], undefined, State>({
  actionPrefix: controlName,
  actionName: "loadQuizzes",
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/GetQuizzes`,
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.loadQuizzes = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadQuizzes.isLoading = false;
    state.loadQuizzes.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadQuizzes.isLoading = false;
    state.loadQuizzes.error = action.payload;
  },
});

var loadQuizResults = createAsyncAction<
  LoadQuizResultsReturn[], //return type
  { quizName: string }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadQuizResults",
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/GetQuizResults`,
  postAsJson: true,
  getAuthBearerToken,
  pending: (state, action) => {
    state.loadQuizResults = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadQuizResults.isLoading = false;
    state.loadQuizResults.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadQuizResults.isLoading = false;
    state.loadQuizResults.error = action.payload;
  },
});

var loadPendingQuizzes = createAsyncAction<
  PendingQuizReturn[],
  { quizName: string },
  State
>({
  actionPrefix: controlName,
  actionName: "getPendingQuizzes",
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/GetPendingQuizzes`,
  postAsJson: true,
  getAuthBearerToken,
  pending: (state) => {
    state.loadPendingQuizzes = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadPendingQuizzes.isLoading = false;
    state.loadPendingQuizzes.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadPendingQuizzes.isLoading = false;
    state.loadPendingQuizzes.error = action.payload;
  },
});

var loadQuizResult = createAsyncAction<
  { content: string },
  { id: number },
  State
>({
  actionPrefix: controlName,
  actionName: "getQuizResult",
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/GetQuizResult`,
  postAsJson: true,
  getAuthBearerToken,
  pending: () => {},
  fulfilled: (state, action) => {
    state.loadQuizResults.data = state.loadQuizResults.data?.map((e) =>
      e.quizId === action.params.id
        ? { ...e, quizResultsData: action.payload.content }
        : e
    );
  },
  rejected: (state, action) => {
    state.loadQuizResults.error = action.payload;
  },
});

var loadPendingQuiz = createAsyncAction<
  { content: string },
  { quizGuid: string },
  State
>({
  actionPrefix: controlName,
  actionName: "getPendingQuiz",
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/GetPendingQuiz`,
  postAsJson: true,
  getAuthBearerToken,
  pending: () => {},
  fulfilled: (state, action) => {
    state.loadPendingQuizzes.data = state.loadPendingQuizzes.data?.map((e) =>
      e.id === action.params.quizGuid
        ? { ...e, quizResultsData: action.payload.content }
        : e
    );
  },
  rejected: (state, action) => {
    state.loadPendingQuizzes.error = action.payload;
  },
});

type DeleteQuizzesReturn = {};

var deleteQuizzes = createAsyncAction<
  DeleteQuizzesReturn, //return type
  { quizIds: number[] }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "deleteQuizzes",
  postAsJson: true,
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/DeleteQuizzes`,
  pending: (state, action) => {
    state.deleteQuizzes = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.deleteQuizzes.isLoading = false;
    state.deleteQuizzes.data = action.payload;

    if (state.loadQuizResults.data) {
      //now remove from list
      // var foundItems =
      //   state.loadQuizResults.data?.filter(
      //     (e) => action.params.quizIds.indexOf(e.quizId) >= 0
      //   ) || [];

      // foundItems.forEach((item) => {
      //   var index = state.loadQuizResults.data?.indexOf(item) || -1;

      //   if (index >= 0) {
      //     state.loadQuizResults.data?.splice(index, 1);
      //   }
      // });
      state.loadQuizResults.data = state.loadQuizResults.data.filter(
        (e) => !action.params.quizIds.includes(e.quizId)
      );
      if (state.loadQuizResults.data.length === 0) window.location.reload();
    }
  },
  rejected: (state, action) => {
    state.deleteQuizzes.isLoading = false;
    state.deleteQuizzes.error = action.payload;
  },
});

var archiveQuiz = createAsyncAction<{}, { quizGuid: string }, State>({
  actionPrefix: controlName,
  actionName: "archiveQuiz",
  postAsJson: true,
  getAuthBearerToken,
  url: `${process.env.REACT_APP_API_BASE}/QuizManager/ArchiveQuiz`,
  pending: () => {},
  fulfilled: () => {
    setTimeout(() => document.getElementById("btnRefresh")?.click());
  },
  rejected: () => {},
});

type State = {
  loadQuizzes: ReducerState<string[]>;
  loadQuizResults: ReducerState<LoadQuizResultsReturn[]>;
  loadPendingQuizzes: ReducerState<PendingQuizReturn[]>;
  deleteQuizzes: ReducerState<DeleteQuizzesReturn>;
};

const initialState: State = {
  loadQuizzes: { isLoading: false },
  loadQuizResults: {
    isLoading: false,
  },
  loadPendingQuizzes: {
    isLoading: false,
  },
  deleteQuizzes: {
    isLoading: false,
  },
};

var slice = createSlice({
  name: controlName,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    ...loadQuizzes.reducer,
    ...loadQuizResults.reducer,
    ...loadPendingQuizzes.reducer,
    ...loadQuizResult.reducer,
    ...loadPendingQuiz.reducer,
    ...deleteQuizzes.reducer,
    ...archiveQuiz.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadQuizzes: loadQuizzes.action,
      loadQuizResults: loadQuizResults.action,
      loadPendingQuizzes: loadPendingQuizzes.action,
      loadQuizResult: loadQuizResult.action,
      loadPendingQuiz: loadPendingQuiz.action,
      deleteQuizzes: deleteQuizzes.action,
      archiveQuiz: archiveQuiz.action,
    },
  },
};

export default duck;
