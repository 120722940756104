import { useMsal } from "@azure/msal-react";
import AccountButton from "components/MSAL/MSALAccountButton/AccountButton";
import React from "react";
import { performLogin } from "store/getAuthBearerToken";

export type Props = {};

const NotLoggedInPanel = (props: Props) => {
  const { instance } = useMsal();

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        You must be logged in to view this page.
      </div>
      <div>
        <AccountButton
          isAuthenticated={false}
          onLogInClick={() => {
            performLogin(instance);
          }}
          onLogOffClick={() => {}}
        />
      </div>
    </div>
  );
};

export default NotLoggedInPanel;
