import { Button, Dialog, IconButton, useMediaQuery } from "@mui/material";
import { SimpleDialog } from "@nait-aits/ui";
import FileInformation from "./components/FileInformation";
import FileUploader from "components/FileUploader/FileUploader";
import useConfirm from "components/hooks/useConfirm";
import { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styled from "styled-components";
import FileGrid from "./components/FileGrid";
import { useReduxState } from "store";

export type FileItemType = {
  name: string;
  subTitle?: string;
  createdDate?: string;
  fullPath: string;
  imageUrl?: string;
  isFolder?: boolean;
  isImage?: boolean;
  metaData?: {
    key: string;
    value: string;
  }[];
};

export type Props = {
  items: FileItemType[];
  title: string;
  currentPath?: string;
  imageWidth?: number;
  imagesPerPage?: number;
  onFileDeleted?: (fullPath: string) => void;
  onFolderClick?: (fullPath: string) => void;
  onFilesUploaded?: (files: File[], subfolderName?: string) => void;
};

function getPathItems(pathString?: string) {
  if (!pathString) return undefined;

  var tmp = pathString.split("/").filter((e) => e);

  var ret: {
    label: string;
    path: string;
  }[] = [];

  var runningPath = "";

  tmp.forEach((element) => {
    runningPath = `${runningPath}${element}/`;
    ret.push({
      label: element,
      path: runningPath,
    });
  });

  return ret;
}

const FileList = (props: Props) => {
  const {
    items,
    // currentPath,
    imagesPerPage = 20,
    // onFilesUploaded = () => {},
    onFileDeleted = () => {},
    onFolderClick = () => {},
  } = props;

  const [openConfirm, ConfirmBox] = useConfirm();

  const [selectedImagePath, setSelectedImagePath] = useState<
    string | undefined
  >();

  var allImages = items.filter((e) => !e.isFolder && e.isImage);

  var selectedImage = allImages.find((e) => e.fullPath === selectedImagePath);

  return (
    <div>
      {ConfirmBox}
      <FileGrid
        items={items}
        imagesPerPage={imagesPerPage}
        onFolderClick={onFolderClick}
        onImageSelected={setSelectedImagePath}
      />
      {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
        {items
          .filter((e) => e.isFolder)
          .map((item, index) => (
            <FileItem
              key={`${item.fullPath}_${index}`}
              fullPath={item.fullPath}
              name={item.name}
              createdDate={item.createdDate}
              imageUrl={item.imageUrl}
              imageWidth={imageWidth}
              isFolder={item.isFolder}
              onImageSelected={() => {
                onFolderClick(item.fullPath);
              }}
            />
          ))}
        {imagesToShow.map((item, index) => (
          <FileItem
            key={`${item.fullPath}_${index}`}
            fullPath={item.fullPath}
            name={item.name}
            createdDate={item.createdDate}
            imageUrl={item.imageUrl}
            imageWidth={imageWidth}
            onImageSelected={() => {
              setSelectedImagePath(item.fullPath);
            }}
          />
        ))}
      </div>
      {numberToTake < allImages.length && (
        <div style={{ textAlign: "center" }}>
          <div>
            {imagesToShow.length} of {allImages.length} images
          </div>
          <Button
            onClick={() => {
              setNumberToTake(numberToTake + imagesPerPage);
            }}
          >
            Load More
          </Button>
        </div>
      )} */}
      {selectedImage && (
        <SimpleDialog
          handleBackgroundClick={() => setSelectedImagePath(undefined)}
        >
          <FileInformation
            {...selectedImage}
            onDelete={() => {
              setSelectedImagePath(undefined);

              openConfirm({
                message: "Are you sure you want to delete this image?",
                onOk: () => {
                  selectedImage && onFileDeleted(selectedImage.fullPath);
                },
                onCancel: () => {
                  selectedImage && setSelectedImagePath(selectedImage.fullPath);
                },
              });
            }}
          />
        </SimpleDialog>
      )}
    </div>
  );
};

export const PathAndUpload = ({
  currentPath,
  onFolderClick,
  onFilesUploaded,
}: Pick<Props, "currentPath" | "onFolderClick" | "onFilesUploaded">) => {
  const { isLoading } = useReduxState((e) => e.blobBrowser.browserItems);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const pathItems = getPathItems(currentPath);
  const isSmall = useMediaQuery("(max-width:1000px)");
  const upperPath =
    pathItems && pathItems.length > 1
      ? pathItems[pathItems.length - 2]
      : undefined;

  return (
    <>
      <div style={{ display: isSmall ? "inline-block" : "flex" }}>
        {pathItems && !isSmall && (
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <PathItem
                currentPath={currentPath === ""}
                onClick={() => onFolderClick?.("")}
              >
                <HomeIcon style={{ marginTop: 5 }} />
              </PathItem>
              <Separator />
            </div>
            {pathItems.map((item) => (
              <div key={item.path} style={{ display: "flex" }}>
                <PathItem
                  currentPath={currentPath === item.path}
                  onClick={() => {
                    if (currentPath !== item.path) onFolderClick?.(item.path);
                  }}
                >
                  {item.label}
                </PathItem>
                <Separator />
              </div>
            ))}
          </div>
        )}
        {isSmall && pathItems && (
          <b title={currentPath}>{pathItems[pathItems.length - 1].label}</b>
        )}
        {isSmall && currentPath && (
          <IconButton
            title={`Go to ${upperPath?.path ?? "Root"}`}
            disabled={isLoading}
            style={{ margin: "auto 5px", color: isLoading ? "grey" : "white" }}
            onClick={() => onFolderClick?.(upperPath?.path ?? "")}
          >
            <ArrowUpwardIcon />
          </IconButton>
        )}
        {currentPath && (
          <Button
            disabled={isLoading}
            style={{ color: isLoading ? "grey" : "white" }}
            onClick={() => !isLoading && setShowUploadDialog(true)}
            variant="contained"
          >
            Upload Image
          </Button>
        )}
      </div>
      {showUploadDialog && (
        <Dialog open onClose={() => setShowUploadDialog(false)}>
          <div style={{ margin: 20 }}>
            <FileUploader
              onFilesUploaded={(files, subfolderName) => {
                onFilesUploaded?.(files, subfolderName);
                setShowUploadDialog(false);
              }}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

const PathItem = styled.div<{ currentPath: boolean }>`
  font-size: 1rem;
  margin: auto 5px;
  ${(props) =>
    props.currentPath
      ? `
      cursor: default;
      font-weight: bold;
    `
      : `
      cursor: pointer;
      color: #fff;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    `}
`;
const Separator = styled(ArrowForwardIosIcon)`
  font-size: 1rem !important;
  margin: auto 5px;
  color: #669df6;
`;

export default FileList;
