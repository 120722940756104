//The commented items show how to include ducks
//import sampleDuck from "./Sample/samplePage.duck";
import sampleDuck from "./BlobBrowser/blobBrowser.duck";
import quizManager from "./QuizManager/quizManager.duck";
import quizEditor from "./QuizEditor/quizEditor.duck";

const index = {
  reducer: {
    ...sampleDuck.reducer,
    ...quizManager.reducer,
    ...quizEditor.reducer,
  },
  actions: {
    ...sampleDuck.actions,
    ...quizManager.actions,
    ...quizEditor.actions,
  },
};

export default index;
