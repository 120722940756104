import { msalInstance } from "index";
import { loginRequest } from "authConfig";
import { FetchError, getAuthBearerToken } from "@nait-aits/redux";
import { IPublicClientApplication } from "@azure/msal-browser";

export function performLogin(
  instance: IPublicClientApplication,
  loginCallback?: () => void
) {
  instance
    .loginRedirect(loginRequest)
    .then((e) => {
      loginCallback && loginCallback();
    })
    .catch((e) => {
      console.log(e);
    });
}

async function getToken(){    
  var ret = await getAuthBearerToken(msalInstance, loginRequest);

  //if token is no longer valid, force relogin
  if (ret instanceof FetchError) {
    if (ret.errorCode === "invalid_grant") {
      performLogin(msalInstance);
    }

    return ret;
  } else {
    return ret;
  }
};

export default getToken;

export { getToken as getAuthBearerToken };
