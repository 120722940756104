import styled from "styled-components";
import { Provider } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import pageHistory from "pageHistory";
import store from "store";
import BlobBrowser from "pages/BlobBrowser/BlobBrowser";
import NotLoggedIn from "pages/NotLoggedIn/NotLoggedIn";

import { useIsAuthenticated } from "@azure/msal-react";
import QuizManager from "pages/QuizManager/QuizManager";
import { useEffect, useState } from "react";
import Home from "pages/Home/Home";
// import QuizEditor from "pages/QuizEditor/QuizEditor";

function App() {
  const isAuthenticated = useIsAuthenticated();

  //
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  //this prevents the non auth showing before load
  if (!init) {
    return <></>;
  }

  return (
    <Provider store={store}>
      {process.env.REACT_APP_IS_PROD !== "true" && <Watermark />}
      <Router history={pageHistory}>
        <Switch>
          <Route path="/Quiz" exact>
            {isAuthenticated && <QuizManager />}
            {!isAuthenticated && <NotLoggedIn />}
          </Route>
          {/* <Route
            path="/Images/:t+"
            render={({ match, location }) => {
              console.log(match);
              console.log(location);
              return isAuthenticated ? (
                <BlobBrowser selectedPath={`${match.params.t}/`} />
              ) : (
                <NotLoggedIn />
              );
            }}
          /> */}
          {/* <Route path="/Editor">
            {isAuthenticated && <QuizEditor />}
            {!isAuthenticated && <NotLoggedIn />}
          </Route> */}
          <Route path="/Images" exact>
            {isAuthenticated && <BlobBrowser />}
            {!isAuthenticated && <NotLoggedIn />}
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Redirect exact from="*" to="/" />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

const Watermark = styled.div`
  position: fixed;
  top: calc(50% - 300px);
  left: 0;
  bottom: 0;
  right: 0;
  color: #ccc;
  font-size: 300px;
  text-align: center;
  z-index: -1;
  transform: rotate(-30deg);
  &:before {
    content: "Testing";
  }
`;
