import moment from "moment";
import styled from "styled-components";
import FolderIcon from "@mui/icons-material/FolderOpenTwoTone";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

export type Props = {
  name: string;
  subTitle?: string;
  createdDate?: string;
  fullPath: string;
  imageUrl?: string;
  imageWidth?: number;
  isFolder?: boolean;
  metaData?: {
    key: string;
    value: string;
  }[];

  onImageSelected: () => void;
  onDelete?: () => void;
};

const FileItem = (props: Props) => {
  const {
    imageUrl,
    imageWidth = 300,
    isFolder,
    name,
    createdDate,
    onImageSelected,
    onDelete,
  } = props;

  return (
    <FileWrapper onClick={onImageSelected}>
      {!isFolder && (
        <img
          src={imageUrl}
          style={{
            maxWidth: imageWidth,
            maxHeight: imageWidth,
            margin: "auto",
            borderRadius: 10,
          }}
          alt={name}
        />
      )}
      {isFolder && (
        <div style={{ width: imageWidth, textAlign: "center" }}>
          <FolderIcon
            style={{
              width: "100%",
              height: "100%",
              maxWidth: imageWidth - 100,
              margin: "auto",
              fill: "#ffd150",
            }}
          />
        </div>
      )}
      <FileInfo>
        <div style={{ width: "100%" }}>
          <div>{name}</div>
          <div>
            <small>
              {isFolder
                ? "Folder"
                : moment.utc(createdDate).local().format("YYYY-MM-DD hh:mm A")}
            </small>
          </div>
        </div>
        {onDelete && (
          <div>
            <IconButton
              aria-label={`delete ${name}`}
              style={{ color: "white" }}
              onClick={(event) => {
                onDelete?.();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </FileInfo>
    </FileWrapper>
  );
};

const FileWrapper = styled.div`
  grid-column-end: span 1;
  grid-row-end: span 1;
  position: relative;
  cursor: pointer;
  margin: 5px;
  display: inline-grid;
  opacity: 0.8;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  &:hover {
    opacity: 1;
  }
`;

const FileInfo = styled.div`
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 10px;
  background-color: #00000096;
  display: flex;
`;

export default FileItem;
