import { useEffect, useState } from "react";
import CollapsedIcon from "@mui/icons-material/ChevronRight";
import ExpandedIcon from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import LoadingIcon from "@mui/icons-material/HourglassBottom";

import styled from "styled-components";

export type Props = {
  items: DirectoryItem[];
  selectedPath?: string;
  onPathExpanded?: (fullPath: string) => void;
  onPathSelected?: (fullPath: string) => void;
};

const FolderList = (props: Props) => {
  const {
    items,
    selectedPath,
    onPathExpanded = () => {},
    onPathSelected = () => {},
  } = props;

  const [selectedItem, setSelectedItem] = useState("");

  const [expandedPaths, setExpandedPaths] = useState<string[]>([]);

  useEffect(() => {
    //we may need to update expanded items if the selected item is changed
    if (selectedPath && selectedPath !== selectedItem) {
      items
        .filter((e) => {
          return selectedPath.startsWith(e.fullPath);
        })
        .forEach((item) => {
          if (expandedPaths.indexOf(item.fullPath) < 0) {
            expandedPaths.push(item.fullPath);
          }
        });

      setExpandedPaths([...expandedPaths]);

      setSelectedItem(selectedPath);

      document.getElementById(selectedPath)?.scrollIntoView();
    }
    //eslint-disable-next-line
  }, [selectedPath]);

  return (
    <div>
      {items
        .filter((e) => e.fullPath === `${e.name}/`)
        .map((directory) => (
          <div key={directory.fullPath}>
            <Directory
              allItems={items}
              selectedPath={selectedItem}
              directory={directory}
              expandedPaths={expandedPaths}
              onSelect={(fullPath) => {
                if (selectedItem === fullPath) {
                } else {
                  setSelectedItem(fullPath);
                  onPathSelected(fullPath);
                }
              }}
              onToggle={(fullPath: string) => {
                var index = expandedPaths.indexOf(fullPath);

                console.log(fullPath);

                if (index < 0) {
                  expandedPaths.push(fullPath);
                  onPathExpanded(fullPath);
                } else {
                  expandedPaths.splice(index, 1);
                }

                setExpandedPaths([...expandedPaths]);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export type DirectoryItem = {
  name: string;
  fullPath: string;
  isLoading?: boolean;
};

const Directory = (props: {
  directory: DirectoryItem;
  allItems: DirectoryItem[];
  expandedPaths: string[];
  onToggle: (fullPath: string) => void;
  onSelect: (fullPath: string) => void;
  selectedPath: string;
}) => {
  const {
    directory,
    allItems,
    expandedPaths,
    onToggle,
    onSelect,
    selectedPath,
  } = props;

  var tmp = `${directory.fullPath}`;

  if (!tmp.endsWith("/")) {
    tmp += "/";
  }

  var children = allItems.filter(
    (e) => e.fullPath === `${tmp}${e.name}` || e.fullPath === `${tmp}${e.name}/`
  );

  var isExpanded = !!expandedPaths.find((e) => e === directory.fullPath);

  return (
    <MenuItem
      id={directory.fullPath}
      isSelected={selectedPath === directory.fullPath}
    >
      <div>
        <DirectoryRow
          onClick={() => {
            onSelect(directory.fullPath);
          }}
        >
          <ExpandCol
            onClick={(e) => {
              onToggle(directory.fullPath);
              e.stopPropagation();
            }}
          >
            {directory.isLoading ? (
              <LoadingIcon />
            ) : isExpanded ? (
              children.length > 0 ? (
                <ExpandedIcon />
              ) : (
                <></>
              )
            ) : (
              <CollapsedIcon />
            )}
          </ExpandCol>
          <FolderCol>
            <FolderIcon />
          </FolderCol>
          <NameCol>{props.directory.name}</NameCol>
        </DirectoryRow>
      </div>
      <div style={{ paddingLeft: 10 }}>
        {!directory.isLoading &&
          expandedPaths.indexOf(directory.fullPath) >= 0 && (
            <div>
              {children.map((e) => (
                <div key={e.fullPath}>
                  <Directory
                    allItems={allItems}
                    expandedPaths={expandedPaths}
                    directory={e}
                    onToggle={onToggle}
                    onSelect={onSelect}
                    selectedPath={selectedPath}
                  />
                </div>
              ))}
            </div>
          )}
      </div>
    </MenuItem>
  );
};

const DirectoryRow = styled.div`
  display: flex;
  cursor: pointer;
`;
const ExpandCol = styled.div`
  width: 30px;
`;
const FolderCol = styled.div`
  width: 30px;
`;

const NameCol = styled.div`
  margin: auto 0 auto 5px;
`;

const MenuItem = styled.div<{ isSelected: boolean }>`
  & > div:first-child {
    padding-block: 5px;
    ${(props) => props.isSelected && `color: #669df6;`}
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

export default FolderList;
