import { useRef } from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import useOnClickOutside from "./useOnClickOutside";

export type Props = {
  setMenuClosed(): void;
  isOpen: boolean;
  handleLogOff(): void;
};

export default function HoverMenu({
  setMenuClosed,
  handleLogOff,
  isOpen,
}: Props) {
  const ref = useRef(null);

  useOnClickOutside(ref, setMenuClosed);

  if (!isOpen) return null;

  return (
    <Container ref={ref}>
      <Button
        onClick={() => {
          handleLogOff();
          setMenuClosed();
        }}
      >
        Log Out
      </Button>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 50px;
  right: -28px;
  color: #000;
  padding: 1em;
  width: 200px;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;
