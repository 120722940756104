import { Button } from "@mui/material";
import MSALAccountButton from "components/MSAL/MSALAccountButton/MSALAccountButton";
import React from "react";
import { useHistory } from "react-router-dom";

export type Props = {
  hideHome?: boolean;
};

const HeaderButtons = (props: Props) => {
  var history = useHistory();

  const { hideHome } = props;

  return (
    <div style={{ display: "flex" }}>
      {!hideHome && (
        <div>
          <Button
            style={{ color: "white" }}
            onClick={() => {
              history.push("/");
            }}
          >
            Home
          </Button>
        </div>
      )}
      <MSALAccountButton />
    </div>
  );
};

export default HeaderButtons;
