import {
  Button,
  TextField,
} from "@mui/material";
import { useState } from "react";
import Dropzone from "react-dropzone";
import FileGrid from "components/FileList/components/FileGrid";

export type Props = {
  onFilesUploaded?: (files: File[], subFolderName: string) => void;
};

const FileUploader = (props: Props) => {
  const [files, setFiles] = useState<File[] | undefined>();
  const [subFolder, setSubFolder] = useState("");

  const { onFilesUploaded = () => {} } = props;

  return (
    <>
      {(!files || files.length === 0) && (
        <Dropzone
          accept={[".jpg", ".jpeg", ".png", ".gif"]}
          onDrop={(e) => {
            setFiles(e);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{ padding: 20, cursor: "pointer" }}
              >
                <input {...getInputProps()} />
                <p>Click or drop the images here to upload.</p>
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {files && files.length > 0 && (
        <div>
          <h1>Files to Upload</h1>
          <p>
            Remove any files you do not want to upload. If you want to place
            these images into a subfolder, enter the name of the folder below.
          </p>
          <p>
            <TextField
              placeholder="Subfolder Name..."
              label={"Sub folder"}
              value={subFolder}
              onChange={(e) => {
                setSubFolder(e.target.value);
                // setSubFolder(e.target.value.replaceAll(" ", "").toLowerCase());
              }}
            />
          </p>
          <div>
            <FileGrid
              items={files.map((e) => {
                return {
                  fullPath: e.name,
                  name: e.name,
                  isImage: true,
                  imageUrl: URL.createObjectURL(e),
                };
              })}
              onDelete={(path) => {
                var found = files.find((f) => f.name === path);
                if (found) {
                  var index = files.indexOf(found);
                  files.splice(index, 1);
                  setFiles([...files]);
                }
              }}
            />
          </div>
          {/* <ImageList cols={4} gap={5}>
            {files.map((file, i) => {
              return (
                <ImageListItem key={`${file.name}_${i}`}>
                  <img src={URL.createObjectURL(file)} alt={file.name} />
                  <ImageListItemBar
                    title={file.name}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${file.name}`}
                        onClick={(event) => {
                          // setShowMenu(true);
                          // setAnchorEl(event.currentTarget);

                          var found = files.find((e) => e.name === file.name);

                          if (found) {
                            var index = files.indexOf(found);
                            files.splice(index, 1);
                            setFiles([...files]);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  ></ImageListItemBar>
                </ImageListItem>
              );
            })}
          </ImageList> */}
        </div>
      )}
      {/* {files && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {files.map((file, i) => {
            return (
              <div key={`img_${i}`}>
                <img
                  alt={file.name}
                  title={file.name}
                  width={100}
                  src={URL.createObjectURL(file)}
                />
              </div>
            );
          })}
        </div>
      )} */}
      <Button
        disabled={!files}
        onClick={() => {
          if (files) {
            onFilesUploaded(files, subFolder);
          }
          setFiles(undefined);
        }}
      >
        Upload
      </Button>
      {files && (
        <Button
          color={"secondary"}
          onClick={() => {
            setFiles(undefined);
          }}
        >
          Clear
        </Button>
      )}
    </>
  );
};

export default FileUploader;
