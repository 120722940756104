import FolderList, { DirectoryItem } from "components/FolderList/FolderList";
import { NAIT } from "components/Icons/Icons";
import React, { ReactElement } from "react";
import styled from "styled-components";

export type Props = {
  title: string;
  items: DirectoryItem[];
  logo?: ReactElement;
  selectedPath?: string;
  onPathExpanded?: (fullPath: string) => void;
  onPathSelected?: (fullPath: string) => void;
};

const FolderListSideMenu = (props: Props) => {
  const {
    title = "Listing",
    items,
    onPathExpanded,
    onPathSelected,
    selectedPath,
    logo = <NAIT />,
  } = props;

  return (
    <SideMenu>
      <SideMenuTitle>
        {logo}
        <span>{title}</span>
      </SideMenuTitle>
      <SideMenuContent>
        <FolderList
          items={items}
          selectedPath={selectedPath}
          onPathExpanded={onPathExpanded}
          onPathSelected={onPathSelected}
        />
      </SideMenuContent>
    </SideMenu>
  );
};

const SideMenu = styled.div`
  background-color: #1b3451;
  color: #fff;
`;

const SideMenuTitle = styled.div`
  height: 64px;
  background-color: #051e34;
  color: white;
  text-transform: uppercase;
  padding: 0.5em 0.25em;

  font-weight: 500;

  & > svg {
    height: 3rem !important;
    width: 3rem !important;
    vertical-align: middle;
  }
  & > span {
    padding-left: 0.5em;
  }
`;

const SideMenuContent = styled.div`
  overflow: auto;
  height: calc(100vh - 75px);
  padding-top: 10px;
`;

export default FolderListSideMenu;
