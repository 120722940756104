import { createSlice } from "@reduxjs/toolkit";
import { createAsyncAction, ReducerState } from "@nait-aits/redux";

//uncomment if you need msal auth
//import {getAuthBearerToken} from "store";

const controlName = "quizEditor";

type Point = {
  id: string;
  x: number;
  y: number;
};

type Term = {
  id: string;
  name?: string;
  points?: Point[];
};

type Slice = {
  id: string;
  imageUrl?: string;
  terms?: Term[];
};

type Plane = {
  id: string;
  name?: string;
  slices?: Slice[];
};

type Anatomy = {
  id: string;
  name?: string;
  planes?: Plane[];
};

type LoadViewerDataReturn = {
  id: string;
  name?: string;
  imageBaseUrl?: string;
  anatomies?: Anatomy[];
};

var loadViewerData = createAsyncAction<
  LoadViewerDataReturn, //return type
  {id?:string}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "loadViewerData",
  url: `${process.env.REACT_APP_API_BASE}/Testing/test`,
  pending: (state, action) => {
    state.loadViewerData = { isLoading: true };
  },
  fulfilled: (state, action) => {
    state.loadViewerData.isLoading = false;
    state.loadViewerData.data = action.payload;
  },
  rejected: (state, action) => {
    state.loadViewerData.isLoading = false;
    state.loadViewerData.error = action.payload;
  },
});

type State = {
  loadViewerData: ReducerState<LoadViewerDataReturn>;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    loadViewerData: {
      isLoading: false,
    },
  } as State,
  reducers: {},
  extraReducers: {
    ...loadViewerData.reducer,
  },
});

const duck = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      loadViewerData: loadViewerData.action,
    },
  },
};

export default duck;
