import React, { useEffect } from "react";

import { MainPageTemplate } from "@nait-aits/ui";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import { useIsAuthenticated } from "@azure/msal-react";
import NotLoggedInPanel from "components/NotLoggedIn/NotLoggedInPanel";
import HeaderButtons from "components/HeaderButtons/HeaderButtons";

const Home = () => {
  var history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    history.replace("/");
    document.title = "DI Viewer Manager";
  }, []);

  return (
    <div>
      <MainPageTemplate
        appBarHeadingText="DI Viewer Manager"
        responsiveBody={true}
        appBarRightContent={<HeaderButtons hideHome />}
        body={
          <div>
            {!isAuthenticated && <NotLoggedInPanel />}
            {isAuthenticated && (
              <div>
                <ItemWrapper>
                  <h2>Image Browser</h2>
                  <div>Manage images for your DI Viewers.</div>
                  <div style={{ margin: 20 }}>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        history.push("/Images");
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <h2>DI Viewer Quiz Manager</h2>
                  <div>Manage DI Viewer Quiz submissions.</div>
                  <div style={{ margin: 20 }}>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        history.push("/Quiz");
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </ItemWrapper>
                <ItemWrapper>
                  <h2>DI Viewer Editor</h2>
                  <div>Edit DI Viewer Assessments.</div>
                  <div style={{ margin: 20 }}>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_DIVIEWER_EDITOR_URL}`
                        );
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </ItemWrapper>
                {/* <ItemWrapper>
                  <h2>DI Viewer Editor (In Progress)</h2>
                  <div>Edit DI Viewer Assessments.</div>
                  <div style={{ margin: 20 }}>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        history.push("/Editor");
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </ItemWrapper> */}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

const ItemWrapper = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
`;

export default Home;
