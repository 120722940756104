import pages from "pages/reducer";

const reducers = {
  ...pages.reducer,
};

const actions = {
  ...pages.actions,
};

const index = {
  reducers,
  actions,
};

export default index;