import reducers from "reducers";
import { createStore } from "@nait-aits/redux";

var store = createStore(reducers.reducers, reducers.actions);

export default store.store;

const useReduxState = store.useReduxState;
const useActions = store.useActions;

export { useReduxState, useActions};